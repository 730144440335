import { Section } from "@containers";
import { graphql } from "gatsby";
import React, { Fragment, ReactElement } from "react";
import { Helmet } from "react-helmet";

const PageTemplate = ({
    data: {
        contentfulPage: { title, sections },
    },
}: unknown): ReactElement => (
    <Fragment>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title} - Beefy Blokes</title>
        </Helmet>
        {sections?.map((section, index) => (
            <Section key={section.id ?? index} data={section} />
        ))}
    </Fragment>
);

export default PageTemplate;

export const query = graphql`
    query ($slug: String!) {
        contentfulPage(slug: { path: { eq: $slug } }) {
            title
            ...SectionData
        }
    }
`;
